import React, { useEffect, useRef, useState, useContext } from "react";
import axios from "axios";
import { API } from "config";
import { AuthContext } from "context/auth";
import { useHistory } from "react-router-dom";
import View from "./login-view";

const Controller = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const source = useRef(null);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const _handleLogin = async (values) => {
    try {
      source.current = axios.CancelToken.source();
      setLoading(true);

      const data = await API.auth.login(source.current.token, values);
      console.log("data: ", data);

      authCtx.login(data?.data, 7);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.data);
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    const _checkIsLogin = () => {
      if (authCtx.isLogin()) {
        if (
          authCtx.user?.role === "ADMINISTRATOR" ||
          authCtx.user?.role === "VALIDATOR"
        ) {
          history.push("/dashboard");
          return;
        }
        history.push("/");
      }
    };

    _checkIsLogin();
  }, [authCtx, history]);

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);
  return (
    <View isLoading={isLoading} handleLogin={_handleLogin} error={error} />
  );
};

export default Controller;
