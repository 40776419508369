import React, { useRef, useEffect, useState } from "react";
import { API } from "config";
import axios from "axios";
import View from "./material-detail-view";
import { useParams } from "react-router-dom";

function Controller() {
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState([]);
  const [reloadCount, setReloadCount] = useState(0);
  const source = useRef(null);

  useEffect(() => {
    const _loadData = async () => {
      setLoading(true);
      try {
        source.current = axios.CancelToken.source();
        const data = await API.material.getBySlug(
          source.current.token,
          params.slug
        );
        setData(data?.data);
        setLoading(false);
        return;
      } catch (error) {
        console.log("error: ", error);
        setLoading(false);
        setData([]);
      }
    };
    _loadData();
  }, [params, reloadCount]);

  useEffect(() => {
    const _addView = async () => {
      source.current = axios.CancelToken.source();
      await API.material.addView(source.current.token, data.id);
    };
    if (data) _addView();
  }, [data]);

  return (
    <View
      data={data}
      isLoading={isLoading}
      reload={() => setReloadCount(reloadCount + 1)}
    />
  );
}

export default Controller;
