export const menus = [
  {
    menu: "Site Setting",
    link: "/dashboard/site-setting",
    type: "menu",
    roles: ["ADMINISTRATOR"],
  },
  {
    menu: "Banner",
    link: "/dashboard/banner",
    type: "menu",
    roles: ["ADMINISTRATOR"],
  },
  {
    menu: "Ads",
    link: "/dashboard/ads",
    type: "menu",
    roles: ["ADMINISTRATOR"],
  },
  {
    menu: "Material",
    link: "/dashboard/material",
    type: "menu",
    roles: ["ADMINISTRATOR", "CONTRIBUTOR","VALIDATOR","REGULAR"],
  },
  {
    menu: "Instrument",
    link: "/dashboard/instrument",
    type: "menu",
    roles: ["ADMINISTRATOR"],
  },
  {
    menu: "User",
    link: "/dashboard/user",
    type: "menu",
    roles: ["ADMINISTRATOR"],
  },
  {
    menu: "My Profile",
    link: "/profile",
    type: "menu",
    openNewTab: true,
    roles: ["ADMINISTRATOR","CONTRIBUTOR","VALIDATOR","REGULAR"],
  },
  {
    menu: "Notification",
    link: "/dashboard/notification",
    type: "menu",
    roles: ["ADMINISTRATOR","CONTRIBUTOR","VALIDATOR","REGULAR"],
  },
  // {
  //   type: "subMenu",
  //   menu: "Contoh SubMenu",
  //   key: "/menu",
  //   link: "/menu",
  //   sub: [
  //     {
  //       menu: "Submenu1",
  //       key: "/subMenu1",
  //       link: "/subMenu1"
  //     }
  //   ]
  // }
];
