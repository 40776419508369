import React, { useContext, useRef, useState } from "react";
import { API } from "config";
import { message } from "antd";
import { useQuery } from "react-query";
import { vars } from "config";

import axios from "axios";
import View from "./material-view";
import { AuthContext } from "context/auth";

function Controller() {
  const source = useRef(null);
  source.current = axios.CancelToken.source();
  const authCtx = useContext(AuthContext);
  const [metaPage, setMetaPage] = useState(vars.metaPage);
  const [isLoading, setLoading] = useState(false);

  const query = useQuery(
    "material",
    async () => {
      let rsp;
      if (authCtx.role === "contributor" || authCtx.role === "regular") {
        rsp = await API.material.listAllByContributor(
          source.current.token,
          authCtx.user.id
        );
        return rsp?.data.data;
      }

      if (authCtx.role === "validator") {
        rsp = await API.material.list(source.current.token, "pending");
        return rsp?.data.data;
      }
      rsp = await API.material.list(source.current.token);
      setMetaPage(rsp?.data.meta);
      return rsp?.data.data;
    },
    { initialData: [], initialStale: true }
  );

  const _handleDelete = async (id) => {
    try {
      setLoading(true);
      source.current = axios.CancelToken.source();
      await API.material.delete(source.current.token, id);
      setLoading(false);
      query.refetch();
      message.success("Data berhasil dihapus");
    } catch (error) {
      console.error("error: ", error);
      setLoading(false);
    }
  };

  const _handleValidate = async (material, values) => {
    try {
      setLoading(true);
      const { status, ...answers } = values;
      const payload = {
        status,
        validation_result: JSON.stringify(answers),
      };
      source.current = axios.CancelToken.source();
      await API.material.update(source.current.token, material.id, payload);
      setLoading(false);
      query.refetch();
      message.success("Data validasi berhasil disimpan");
    } catch (error) {
      console.error("error: ", error);
      setLoading(false);
    }
  };

  return (
    <View
      query={query}
      metaPage={metaPage}
      onDelete={_handleDelete}
      isLoading={isLoading}
      role={authCtx.role}
      onValidate={_handleValidate}
    />
  );
}

export default Controller;
