import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/auth";
import { Link } from "react-router-dom";
import { Badge, Menu, Space } from "antd";
import { useLocation } from "react-router-dom";
import { menus } from "config";
import { SidebarWrapper } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { API } from "config";

const { SubMenu } = Menu;

const Index = () => {
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const [notifCount, setNotifCount] = useState(0);

  useEffect(() => {
    const _loadNotifCount = async () => {
      const rsp = await API.notification.count();
      setNotifCount(rsp?.data.amount_notification || 0);
    };
    _loadNotifCount();
  }, []);

  const menuMapping = menus.map((item) => {
    if (!authCtx.isAuthorized(item.roles)) {
      return null;
    }
    if (item.type === "subMenu") {
      return (
        <SubMenu key={item.link} title={<span>{item.menu}</span>}>
          {item.sub.map((sub) => {
            return (
              <Menu.Item key={item.key + sub.key}>
                <Link to={item.link + sub.link}>
                  <span>{sub.menu}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    if (item.type === "menu") {
      return (
        <Menu.Item key={item.link}>
          {item.openNewTab ? (
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <Space>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                <span>{item.menu}</span>
              </Space>
            </a>
          ) : (
            <Link to={item.link}>
              <span>{item.menu}</span>
              {item.link === "/dashboard/notification" && notifCount > 0 && (
                <Badge className="ml-2" count={notifCount} />
              )}
            </Link>
          )}
        </Menu.Item>
      );
    }
    return [];
  });

  return (
    <SidebarWrapper className="h-100">
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={location.pathname}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item>
          <div className="text-center font-weight-bold">Dashboard</div>
        </Menu.Item>
        <Menu.Item>
          <Link to="/">
            <span>Go To Site</span>
          </Link>
        </Menu.Item>
        {menuMapping}

        <div className={"containers-sidebar-logout"} onClick={authCtx.logout}>
          Logout
        </div>
      </Menu>
    </SidebarWrapper>
  );
};

export default Index;
