import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { API } from "config";
import View from "./ads-form-view";
import { FileService } from "services";
import { useHistory, useParams } from "react-router-dom";
import { message } from "antd";

function Controller() {
  const source = useRef(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { id } = useParams();
  const isEdit = typeof id !== "undefined";

  const _handleSubmit = async (values) => {
    if (!isEdit && (!values.image || values.image?.length === 0)) {
      message.error("Gambar belum dipilih!");
      return;
    }

    try {
      source.current = axios.CancelToken.source();
      setLoading(true);

      if (values.image?.length > 0) {
        const { originFileObj, name } = values.image[0];
        const filename = `ads/${new Date().toISOString()}_${name}`;
        const data = await FileService.upload(
          source.current.token,
          originFileObj,
          filename
        );
        values.image_link = data.url;
        delete values.image;
      }

      if (isEdit) {
        await API.ads.update(source.current.token, id, values);
        message.success("Data berhasil diubah");
      } else {
        await API.ads.create(source.current.token, values);
        message.success("Data berhasil disimpan");
      }

      setLoading(false);
      history.push("/dashboard/ads");
    } catch (error) {
      setLoading(false);
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    const _loadData = async () => {
      source.current = axios.CancelToken.source();
      try {
        setLoading(true);

        const data = await API.ads.getByID(source.current.token, id);
        setData(data?.data);

        setLoading(false);
      } catch (error) {
        console.error("error: ", error);
        setLoading(false);
      }
    };
    if (id) _loadData();
  }, [id]);

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);
  return (
    <View
      handleSubmit={_handleSubmit}
      isLoading={isLoading}
      isEdit={isEdit}
      data={data}
    />
  );
}

export default Controller;
