import React, { useRef, useState } from "react";
import { API } from "config";
import { message } from "antd";
import { useQuery } from "react-query";
import { vars } from "config";

import axios from "axios";
import View from "./user-view";

function Controller() {
  const source = useRef(null);
  source.current = axios.CancelToken.source();
  const [metaPage, setMetaPage] = useState(vars.metaPage);
  const [isLoading, setLoading] = useState(false);

  const query = useQuery(
    "material",
    async () => {
      const rsp = await API.user.list(source.current.token);
      setMetaPage(rsp?.data.meta);
      return rsp?.data.data;
    },
    { initialData: [], initialStale: true }
  );

  const _handleDelete = async (id) => {
    try {
      setLoading(true);
      source.current = axios.CancelToken.source();
      await API.user.delete(source.current.token, id);
      setLoading(false);
      query.refetch();
      message.success("Data berhasil dihapus");
    } catch (error) {
      console.error("error: ", error);
      setLoading(false);
    }
  };

  return (
    <View
      query={query}
      metaPage={metaPage}
      onDelete={_handleDelete}
      isLoading={isLoading}
    />
  );
}

export default Controller;
