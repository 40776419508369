import React, { useRef, useEffect, useState } from "react";
import { API } from "config";
import axios from "axios";
import View from "./profile-view";
import { useParams } from "react-router-dom";
import { message } from "antd";

function Controller() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const source = useRef(null);

  const _handleSubmitProfile = async (values) => {
    try {
      setLoading(true);
      source.current = axios.CancelToken.source();
      await API.auth.updateProfile(source.current.token, values);
      setLoading(false);
      message.success("Profile berhasil diperbaharui");
    } catch (error) {
      setLoading(false);
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    const _loadData = async () => {
      setLoading(true);
      try {
        source.current = axios.CancelToken.source();
        const data = await API.auth.getProfile(source.current.token);
        setData(data?.data);
        setLoading(false);
        return;
      } catch (error) {
        console.log("error: ", error);
        setLoading(false);
        setData([]);
      }
    };
    _loadData();
  }, [params]);
  return (
    <View
      data={data}
      isLoading={isLoading}
      handleSubmitProfile={_handleSubmitProfile}
    />
  );
}

export default Controller;
