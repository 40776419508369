import { AdminLayout, AuthLayout, AdminAuthLayout, MainLayout } from "layouts";
import {
  Home,
  Contributor,
  Login,
  MaterialDetail,
  MaterialList,
  AdminPage,
  Profile,
} from "pages";

const layouts = [
  { path: "/authp", component: AdminAuthLayout, isPublic: true },
  { path: "/auth", component: AuthLayout, isPublic: true },
  {
    path: "/dashboard",
    component: AdminLayout,
    isPublic: false,
    roles: ["ADMINISTRATOR", "CONTRIBUTOR","VALIDATOR","REGULAR"],
  },
  { path: "/", component: MainLayout, isPublic: true },
];

const main = [
  { path: "/contributor/:id", component: Contributor, isPublic: true },
  { path: "/material/:slug", component: MaterialDetail, isPublic: true },
  { path: "/material", component: MaterialList, isPublic: true },
  {
    path: "/profile",
    component: Profile,
    isPublic: false,
    roles: ["subscriber", "ADMINISTRATOR", "validator", "CONTRIBUTOR","REGULAR"],
  },
  { path: "/", component: Home, isPublic: true },
];

const adminAuth = [
  { path: "/authp/login", component: AdminPage.Login, isPublic: true },
];

const auth = [{ path: "/auth/login", component: Login, isPublic: true }];

const admin = [
  {
    path: "/dashboard/site-setting",
    component: AdminPage.SiteSetting,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/banner/form/:id?",
    component: AdminPage.BannerForm,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/banner",
    component: AdminPage.Banner,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/ads/form/:id?",
    component: AdminPage.AdsForm,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/ads",
    component: AdminPage.Ads,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/material/form/:id?",
    component: AdminPage.MaterialForm,
    isPublic: false,
    roles: ["ADMINISTRATOR", "CONTRIBUTOR","REGULAR"],
  },
  {
    path: "/dashboard/material",
    component: AdminPage.Material,
    isPublic: false,
    roles: ["ADMINISTRATOR", "CONTRIBUTOR","VALIDATOR","REGULAR"],
  },
  {
    path: "/dashboard/notification",
    component: AdminPage.Notification,
    isPublic: false,
    roles: ["ADMINISTRATOR", "CONTRIBUTOR","VALIDATOR","REGULAR"],
  },
  {
    path: "/dashboard/instrument/form/:id?",
    component: AdminPage.InstrumentForm,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/instrument",
    component: AdminPage.Instrument,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/user/form/:id?",
    component: AdminPage.UserForm,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
  {
    path: "/dashboard/user",
    component: AdminPage.User,
    isPublic: false,
    roles: ["ADMINISTRATOR"],
  },
];

export default { adminAuth, auth, layouts, main, admin };
