import { request } from "utils";

const DEFAULT_PAGE_SIZE = 1000;

const auth = {
  login: (cancelToken, data) =>
    request({
      url: `/v1/auth/login`,
      method: "POST",
      cancelToken,
      data,
    }),
  loginWithGoogle: (cancelToken, token) =>
    request({
      url: `/v1/auth/regis-by-google`,
      method: "POST",
      cancelToken,
      data: { token },
    }),
  check: () =>
    request({
      url: `/v1/auth/check-auth`,
      method: "GET",
    }),
  getProfile: (cancelToken) =>
    request({
      url: `/v1/user/profile`,
      method: "GET",
      cancelToken,
    }),
  updateProfile: (cancelToken, data) =>
    request({
      url: `/v1/user/profile`,
      method: "POST",
      cancelToken,
      data,
    }),
};

const user = {
  list: (cancelToken, page = 1, size = DEFAULT_PAGE_SIZE) =>
    request({
      url: `/v1/user?per_page=${size}&page=${page}`,
      method: "GET",
      cancelToken,
    }),
  getByID: (cancelToken, id) =>
    request({
      url: `/v1/user/${id}`,
      method: "GET",
      cancelToken,
    }),
  create: (cancelToken, data) =>
    request({
      url: `/v1/user`,
      method: "POST",
      data,
      cancelToken,
    }),
  update: (cancelToken, id, data) =>
    request({
      url: `/v1/user/${id}`,
      method: "PUT",
      data,
      cancelToken,
    }),
  delete: (cancelToken, id) =>
    request({
      url: `/v1/user/${id}`,
      method: "DELETE",
      cancelToken,
    }),
};

const ads = {
  list: (cancelToken, page = 1, size = DEFAULT_PAGE_SIZE) =>
    request({
      url: `/v1/ads?per_page=${size}&page=${page}`,
      method: "GET",
      cancelToken,
    }),
  getByID: (cancelToken, id) =>
    request({
      url: `/v1/ads/${id}`,
      method: "GET",
      cancelToken,
    }),
  create: (cancelToken, data) =>
    request({
      url: `/v1/ads`,
      method: "POST",
      data,
      cancelToken,
    }),
  update: (cancelToken, id, data) =>
    request({
      url: `/v1/ads/${id}`,
      method: "PUT",
      data,
      cancelToken,
    }),
  delete: (cancelToken, id) =>
    request({
      url: `/v1/ads/${id}`,
      method: "DELETE",
      cancelToken,
    }),
};

const banner = {
  list: (cancelToken, page = 1, size = DEFAULT_PAGE_SIZE) =>
    request({
      url: `/v1/banners?per_page=${size}&page=${page}`,
      method: "GET",
      cancelToken,
    }),
  getByID: (cancelToken, id) =>
    request({
      url: `/v1/banners/${id}`,
      method: "GET",
      cancelToken,
    }),
  create: (cancelToken, data) =>
    request({
      url: `/v1/banners`,
      method: "POST",
      data,
      cancelToken,
    }),
  update: (cancelToken, id, data) =>
    request({
      url: `/v1/banners/${id}`,
      method: "PUT",
      data,
      cancelToken,
    }),
  delete: (cancelToken, id) =>
    request({
      url: `/v1/banners/${id}`,
      method: "DELETE",
      cancelToken,
    }),
};

const instrument = {
  list: (cancelToken, page = 1, size = DEFAULT_PAGE_SIZE) =>
    request({
      url: `/v1/instrument?per_page=${size}&page=${page}`,
      method: "GET",
      cancelToken,
    }),
  getByID: (cancelToken, id) =>
    request({
      url: `/v1/instrument/${id}`,
      method: "GET",
      cancelToken,
    }),
  create: (cancelToken, data) =>
    request({
      url: `/v1/instrument`,
      method: "POST",
      data,
      cancelToken,
    }),
  update: (cancelToken, id, data) =>
    request({
      url: `/v1/instrument/${id}`,
      method: "PUT",
      data,
      cancelToken,
    }),
  delete: (cancelToken, id) =>
    request({
      url: `/v1/instrument/${id}`,
      method: "DELETE",
      cancelToken,
    }),
};

const file = {
  upload: (cancelToken, data, filename, bucket) =>
    request({
      url: `/v1/file/upload?filename=${filename}&bucket=${bucket}`,
      method: "POST",
      data,
      cancelToken,
    }),
};

const material = {
  list: (cancelToken, status = "posted", page = 1, size = DEFAULT_PAGE_SIZE) =>
    request({
      url: `/v1/material?per_page=${size}&page=${page}&where={"status": "${status}"}`,
      method: "GET",
      cancelToken,
    }),
  listByTitle: (cancelToken, searchText, status = "posted") =>
    request({
      url: `/v1/material?per_page=${DEFAULT_PAGE_SIZE}&page=${1}&where={"or": [{"title": {"contains" : "${searchText}"}}, {"keywords":{ "contains" :"${searchText}"}}], "status": "${status}"}`,
      method: "GET",
      cancelToken,
    }),
  listPopular: (
    cancelToken,
    type,
    size = DEFAULT_PAGE_SIZE,
    status = "posted"
  ) =>
    request({
      url: `/v1/material?per_page=${size}&page=${1}&sort=view_count%20DESC&where={"types":"${type}","status": "${status}"}`,
      method: "GET",
      cancelToken,
    }),
  listByType: (
    cancelToken,
    types,
    status = "posted",
    page = 1,
    size = DEFAULT_PAGE_SIZE
  ) =>
    request({
      url: `/v1/material?per_page=${size}&page=${page}&where={"types":${types}, "status": "${status}"}`,
      method: "GET",
      cancelToken,
    }),
  listAllByContributor: (cancelToken, id) =>
    request({
      url: `/v1/material?per_page=${DEFAULT_PAGE_SIZE}&page=${1}&where={"created_by" : ${id}}`,
      method: "GET",
      cancelToken,
    }),
  listByContributor: (cancelToken, id, status = "posted") =>
    request({
      url: `/v1/material?per_page=${DEFAULT_PAGE_SIZE}&page=${1}&where={"created_by" : ${id}, "status": "${status}"}`,
      method: "GET",
      cancelToken,
    }),
  getByID: (cancelToken, id) =>
    request({
      url: `/v1/material/${id}`,
      method: "GET",
      cancelToken,
    }),
  getBySlug: (cancelToken, slug) =>
    request({
      url: `/v1/material/${slug}/slug`,
      method: "GET",
      cancelToken,
    }),
  create: (cancelToken, data) =>
    request({
      url: `/v1/material`,
      method: "POST",
      data,
      cancelToken,
    }),
  addView: (cancelToken, id) =>
    request({
      url: `/v1/material/${id}/view`,
      method: "PUT",
      cancelToken,
    }),
  rate: (cancelToken, id, rating) =>
    request({
      url: `/v1/material/${id}/rating`,
      method: "PUT",
      data: { rating },
      cancelToken,
    }),
  update: (cancelToken, id, data) =>
    request({
      url: `/v1/material/${id}`,
      method: "PUT",
      data,
      cancelToken,
    }),
  delete: (cancelToken, id) =>
    request({
      url: `/v1/material/${id}`,
      method: "DELETE",
      cancelToken,
    }),
};

const comment = {
  create: (cancelToken, material_id, content) =>
    request({
      url: `/v1/comment`,
      method: "POST",
      data: {
        material_id,
        content,
      },
      cancelToken,
    }),
  listByMaterialID: (cancelToken, material_id) =>
    request({
      url: `/v1/comment/${material_id}`,
      method: "GET",
      cancelToken,
    }),
};

const notification = {
  list: (cancelToken) =>
    request({
      url: `/v1/notifications`,
      method: "GET",
      cancelToken,
    }),
  count: () =>
    request({
      url: `/v1/notifications/count`,
      method: "GET",
    }),
  markHasRead: (id) =>
    request({
      url: `/v1/notifications/read-notif/${id}`,
      method: "POST",
    }),
};

const setting = {
  get: (cancelToken) =>
    request({
      url: `/v1/setting`,
      method: "GET",
      cancelToken,
    }),
  update: (cancelToken, data) =>
    request({
      url: `/v1/setting/create`,
      method: "POST",
      data,
      cancelToken,
    }),
};

export default {
  ads,
  auth,
  banner,
  comment,
  file,
  material,
  notification,
  instrument,
  setting,
  user,
};
