import React from "react";
import { Avatar, Card, Button, Table, Space } from "antd";
import { Link } from "react-router-dom";
import { deleteConfirm } from "components";

function View({ query, onDelete, isLoading }) {
  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      render: (cell, record) => (
        <Space className="d-flex align-items-center">
          <Avatar src={record.picture} />
          {cell}
        </Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Pekerjaan",
      dataIndex: "job",
      key: "job",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active) =>
        is_active ? (
          <span className="text-primary">Aktif</span>
        ) : (
          <span className="text-danger">Tidak Aktif</span>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          {(record.role === "contributor" || record.role === "validator") && (
            <Button type="primary" href={`/dashboard/user/form/${record.id}`}>
              Edit
            </Button>
          )}
          {record.role !== "ADMINISTRATOR" && (
            <>
              {record.is_active && (
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    deleteConfirm({
                      message: "Anda yakin akan menonaktifkan user ini?",
                      confirmLoading: isLoading,
                      onDelete: () => onDelete(record.id),
                    })
                  }
                >
                  Non Aktifkan
                </Button>
              )}
              {!record.is_active && (
                <Button
                  type="primary"
                  onClick={() =>
                    deleteConfirm({
                      message: "Anda yakin akan menonaktifkan user ini?",
                      confirmLoading: isLoading,
                      onDelete: () => onDelete(record.id),
                    })
                  }
                >
                  Aktifkan
                </Button>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];
  return (
    <div className="row">
      <div className="col-12">
        <Card
          title="User Management"
          bordered={false}
          extra={
            <Link to="/dashboard/user/form">
              <Button type="primary">Tambah Validator</Button>
            </Link>
          }
        >
          <Table
            columns={columns}
            dataSource={query.data}
            loading={query.isLoading}
            rowKey="id"
          />
        </Card>
      </div>
    </div>
  );
}

export default View;
