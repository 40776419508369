import React, { useRef, useState, useEffect } from "react";
import { API } from "config";
import { FileService } from "services";
import { message } from "antd";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import View from "./material-form-view";
import { MATERIAL_TYPE } from "constant";

function Controller() {
  const source = useRef(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { id } = useParams();
  const isEdit = typeof id !== "undefined";

  const _handleSubmit = async (values) => {
    try {
      source.current = axios.CancelToken.source();
      setLoading(true);

      if (values.file?.length > 0) {
        if (values.type === MATERIAL_TYPE.BOOK) {
          const { originFileObj, name } = values.file[0];
          const filename = `book/${new Date().toISOString()}_${name}`;
          const data = await FileService.upload(
            source.current.token,
            originFileObj,
            filename
          );
          values.content = data.url;
          delete values.file;
        }
      }

      if (values.cover?.length > 0) {
        const { originFileObj, name } = values.cover[0];
        const filename = `cover/${new Date().toISOString()}_${name}`;
        const data = await FileService.upload(
          source.current.token,
          originFileObj,
          filename
        );
        values.cover_link = data.url;
        delete values.cover;
      }

      if (values.keywords?.length > 0) {
        values.keywords = values.keywords
          .map((val) => val.toLowerCase())
          .join(",");
      }

      if (isEdit) {
        await API.material.update(source.current.token, id, values);
        message.success("Data berhasil diubah");
      } else {
        await API.material.create(source.current.token, values);
        message.success("Data berhasil disimpan");
      }

      setLoading(false);
      history.push("/dashboard/material");
    } catch (error) {
      setLoading(false);
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    const _loadData = async () => {
      source.current = axios.CancelToken.source();
      try {
        setLoading(true);

        const data = await API.material.getByID(source.current.token, id);
        data.type = parseInt(data?.data.type);
        if (data.keywords) {
          data.keywords = data.keywords.split(",");
        }
        setData(data);

        setLoading(false);
      } catch (error) {
        console.error("error: ", error);
        setLoading(false);
      }
    };
    if (id) _loadData();
  }, [id]);

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);
  return (
    <View
      handleSubmit={_handleSubmit}
      isLoading={isLoading}
      isEdit={isEdit}
      data={data}
    />
  );
}

export default Controller;
